import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { CreditCardService } from './services/creditCardService/credit-card.service';
import { CreditCardService as CreditCardHelperService } from './services/credit-card-helper/credit-card.service';
import { DataService } from './services/data/data.service';
import { MessageService } from './services/message/message.service';
import { LoggerService } from './services/logger/logger.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  providers: [
    CreditCardService,
    CreditCardHelperService,
    DataService,
    MessageService,
    LoggerService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
