import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService {
  number: string = '';
  type = 'UNKNOWN';
  valid = false;

  cvv:string = '';
  cvvValid = false;

  constructor() {}
  
  card(cardNumber:string|number, cvv?:string|number) {
    if(!cardNumber) return { number: '', type: 'UNKNOWN', valid: false, cvv: '', cvvValid: false};
    
    this.number = cardNumber.toString();
    this.type = this.identifyType();
    this.valid = this.checkForValid();

    if(cvv) {
      this.cvv = cvv.toString();
    }
    this.cvvValid = this.checkCvvForValid(this.number, this.cvv);

    return {
      number: this.number,
      type: this.type,
      valid: this.valid,
      cvv: this.cvv,
      cvvValid: this.cvvValid
    }
  }

  checkCvvForValid(number:string = this.number, cvv:string = this.cvv):boolean {
    switch( this.identifyType(number) ) {
      case 'visa': {
        return (cvv.length === 3);
      }
      case 'msrcd': {
        return (cvv.length === 3);
      }
      case 'amex': {
        return (cvv.length === 3 || cvv.length === 4);
      }
      case 'discvr': {
        return (cvv.length === 3);
      }
      default: {
        return false;
      }
    }
  }

  checkForValid(number:string = this.number, type:string = this.type):boolean {
    return (this.luhn(number) && this.hasProperCardLength(number, type) );
  }

  hasProperCardLength(number:string = this.number, type:string = this.type):boolean {
    const length = number.length;

    switch(type) {
      case 'visa': {
        return [16].includes(length);
      }
      case 'msrcd': {
        return [16].includes(length);
      }
      case 'amex': {
        return [15].includes(length);
      }
      case 'discvr': {
        return [16].includes(length);
      }
      default: {
        return false;
      }
    }

  }

  identifyType(cardNumber = this.number):string {
    if ( this.visa(cardNumber) ) return "visa";
    if ( this.mastercard(cardNumber) ) return "msrcd";
    if ( this.amex(cardNumber) ) return "amex";
    if ( this.discover(cardNumber) ) return "discvr";
    return "UNKNOWN";
  }

  visa(number:string):boolean {
    const prefix = number.substring(0,1);
    return (prefix === '4') ? true : false;
  }

  mastercard(number:string):boolean {
    let valid = false;

    let prefix:string|number = number.substring(0,2);
    valid = (['51', '52', '53', '54', '55'].includes(prefix)) ? true : valid;

    prefix = parseInt(number.substring(0,6));
    valid = ((prefix >= 222100) && (prefix <=272099)) ? true : valid;

    return valid;
  }

  amex(number:string):boolean {
    const prefix = number.substring(0,2);
    return (['34', '37'].includes(prefix)) ? true : false;
  }

  discover(number:string):boolean {
    let valid = false;

    let prefix:string|number = number.substring(0, 4);
    valid = ('6011' === prefix) ? true : valid;

    prefix = number.substring(0, 3);
    valid = (['644', '645', '646', '647', '648', '649'].includes(prefix)) ? true : valid;

    prefix = parseInt(number.substring(0, 6));
    valid = ((prefix >= 622126) && (prefix <= 622925)) ? true : valid;

    prefix = number.substring(0, 2);
    valid = (prefix === '65') ? true : valid;

    return valid;
  }

  luhn(cardNumber:string = this.number):boolean {
    if(!cardNumber.length){
      return false;
    }
    
    // Remove all whitespaces from card number.
    cardNumber = cardNumber.replace(/\s/g,'');
    
    // 1. Remove last digit;
    const lastDigit = Number(cardNumber[cardNumber.length - 1]);
    
    // 2. Reverse card number
    const reverseCardNumber = cardNumber
      .slice(0,cardNumber.length - 1)
      .split('')
      .reverse()
      .map(x => Number(x));
    
    let sum = 0;
    
    // 3. + 4. Multiply by 2 every digit on odd position.
    // Subtract 9 if digit > 9
    for(let i = 0; i <= reverseCardNumber.length -1; i += 2){
      reverseCardNumber[i] = reverseCardNumber[i]*2;
      if(reverseCardNumber[i] > 9){
        reverseCardNumber[i] = reverseCardNumber[i] - 9;
      }
    }
    
    // 5. Make the sum of obtained values from step 4.
    sum = reverseCardNumber
      .reduce((acc, currValue) => (acc + currValue), 0);
    
    // 6. Calculate modulo 10 of the sum from step 5 and the last digit. 
    // If it's 0, you have a valid card number :)
    return ((sum + lastDigit) % 10 === 0);
  }

}
