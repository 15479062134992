import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

const routes: Routes = [
  { path: '', component: AppComponent },
  {
    path: 'capture',
    loadChildren: () =>
      import('./pages/card-capture/card-capture.module').then(
        (m) => m.CardCaptureModule
      ),
  },
  {
    path: 'success',
    loadChildren: () =>
      import('./pages/success/success.module').then(
        (m) => m.SuccessModule
      ),
  },
  {
    path: 'timx-payment',
    loadChildren: () =>
      import('./pages/timx-payment/timx-payment.module').then(
        (m) => m.TimxPaymentModule
      ),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./pages/common-payment/common-payment.module').then(
        (m) => m.CommonPaymentModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
